<template>
  <div id="dispositivos">
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
    <div v-if="!loading" class="layout row media-layout">
      <v-container grid-list-xl fluid>
        <v-layout wrap>
          <v-flex v-if="locales.length > 0" xs12 sm12>
            <v-toolbar dense color="transparent">
              <v-spacer />
              <v-btn color="primary" @click="mostrarModal('new', 'dispositivo', 0, 0)">{{
                $t('dispositivo.new_dispositivo')
              }}</v-btn>
            </v-toolbar>
            <v-card class="mb-4">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $t('menu.dispositivos') }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-card-text class="">
                <v-data-table
                  :headers="headers_dispositivo"
                  :items="dispositivos"
                  :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                  class="elevation-1"
                  :items-per-page-text="$t('datatable.rows_per_page')"
                  item-key="name"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center" style="width: 35%">{{ props.item.descripcion }}</td>
                      <td class="text-center" style="width: 30%">{{ props.item.local_nombre }}</td>
                      <td class="text-center" style="width: 15%">
                        {{ props.item.tipo === 1 ? $t('dispositivo.venta') : $t('dispositivo.alquiler') }}
                      </td>
                      <td class="text-center" style="width: 20%">
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="blue"
                          small
                          @click="mostrarModal('edit', 'dispositivo', props.item.id, props.index)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="pink"
                          small
                          @click="mostrarModal('delete', 'dispositivo', props.item.id, props.index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12>
            <v-toolbar dense color="transparent">
              <v-spacer />
              <v-btn color="primary" @click="mostrarModal('new', 'entidad', 0, 0)">{{
                $t('dispositivo.new_entidad')
              }}</v-btn>
            </v-toolbar>
            <v-card class="mb-4">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $tc('dispositivo.entidad', 2) }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-card-text class="">
                <v-data-table
                  :headers="headers_entidad"
                  :items="entidades"
                  :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                  class="elevation-1"
                  :items-per-page-text="$t('datatable.rows_per_page')"
                  item-key="name"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center" style="width: 20%">{{ props.item.fecha_alta }}</td>
                      <td style="width: 60%">{{ props.item.nombre }}</td>
                      <td class="text-center" style="width: 20%">
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="blue"
                          small
                          @click="mostrarModal('edit', 'entidad', props.item.id, props.index)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="pink"
                          small
                          @click="mostrarModal('delete', 'entidad', props.item.id, props.index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="personas_contacto.length > 0 && entidades.length > 0" xs12 sm12>
            <v-toolbar dense color="transparent">
              <v-spacer />
              <v-btn color="primary" @click="mostrarModal('new', 'local', 0, 0)">{{
                $t('dispositivo.new_local')
              }}</v-btn>
            </v-toolbar>
            <v-card class="mb-4">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $tc('dispositivo.local', 2) }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-card-text class="">
                <v-data-table
                  :headers="headers_local"
                  :items="locales"
                  :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                  class="elevation-1"
                  :items-per-page-text="$t('datatable.rows_per_page')"
                  item-key="name"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center">{{ props.item.nombre }}</td>
                      <td class="text-center">{{ props.item.entidad_nombre }}</td>
                      <td class="text-center">{{ props.item.persona_name }}</td>
                      <td class="text-center">{{ props.item.address }}</td>
                      <td class="text-center">
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="blue"
                          small
                          @click="mostrarModal('edit', 'local', props.item.id, props.index)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="pink"
                          small
                          @click="mostrarModal('delete', 'local', props.item.id, props.index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12>
            <v-toolbar dense color="transparent">
              <v-spacer />
              <v-btn color="primary" @click="mostrarModal('new', 'persona', 0, 0)">{{
                $t('dispositivo.new_persona')
              }}</v-btn>
            </v-toolbar>
            <v-card class="mb-4">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $tc('dispositivo.persona_contacto', 2) }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-card-text class="">
                <v-data-table
                  :headers="headers_persona"
                  :items="personas_contacto"
                  :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                  class="elevation-1"
                  :items-per-page-text="$t('datatable.rows_per_page')"
                  item-key="name"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center" style="width: 40%">{{ props.item.nombre }} {{ props.item.apellidos }}</td>
                      <td class="text-center" style="width: 20%">{{ props.item.telefono }}</td>
                      <td class="text-center" style="width: 20%">{{ props.item.email }}</td>
                      <td class="text-center" style="width: 20%">
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="blue"
                          small
                          @click="mostrarModal('edit', 'persona', props.item.id, props.index)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="pink"
                          small
                          @click="mostrarModal('delete', 'persona', props.item.id, props.index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog v-model="dialog.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog.titulo }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ dialog.texto }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn color="red darken-1" text @click.native="confirmarDialog"
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="alerta.color"
        top="top"
        :multi-line="mode === 'multi-line'"
        :vertical="mode === 'vertical'"
      >
        <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
        >&nbsp;{{ alerta.texto }}
      </v-snackbar>
    </div>
    <v-dialog v-model="dialogedit" max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialog.titulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout v-if="dialog.tipo === 'entidad'" wrap>
              <v-flex xs12 sm12>
                <v-text-field v-model="editedItem.descripcion" name="input-10-1" :label="$t('common.descripcion')" />
              </v-flex>
            </v-layout>
            <v-layout v-else-if="dialog.tipo === 'persona'" wrap>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.nombre" name="input-10-1" :label="$t('patient.first_name')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.apellidos" name="input-10-1" :label="$t('patient.last_name')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.telefono" name="input-10-1" :label="$t('patient.phone')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.email" name="input-10-1" :label="$t('message.email')" />
              </v-flex>
            </v-layout>
            <v-layout v-else-if="dialog.tipo === 'local'" wrap>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.nombre" name="input-10-1" :label="$t('common.name')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="editedItem.entidad"
                  :items="entidades"
                  :label="$tc('dispositivo.entidad', 1)"
                  auto
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.direccion" name="input-10-1" :label="$t('common.address')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="editedItem.poblacion" name="input-10-1" :label="$t('common.location')" />
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-autocomplete
                  v-model="editedItem.persona"
                  :items="personas_contacto"
                  chips
                  item-text="name"
                  item-value="id"
                  style="width: 98%"
                  :label="$tc('dispositivo.persona_contacto', 1)"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      color="primary lighten-4"
                      :selected="data.selected"
                      close
                      class="chip--select-multi"
                      @click:close="quitarPersona()"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template slot="item" slot-scope="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout v-else-if="dialog.tipo === 'dispositivo'" wrap>
              <v-flex xs12 sm12>
                <v-text-field v-model="editedItem.descripcion" name="input-10-1" :label="$t('common.descripcion')" />
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="editedItem.local"
                  :items="locales"
                  :label="$tc('dispositivo.local', 1)"
                  auto
                  required
                  item-text="nombre"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="editedItem.tipo"
                  :items="tipo"
                  :label="$t('videoconsulta.tipo')"
                  auto
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click.native="dialogedit = false"
            ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click.native="save"
            ><v-icon>mdi-content-save</v-icon> {{ $t('common.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dispositivosUrl, dispositivosEditUrl, dispositivosNewUrl, getHeader } from '../config/config';

export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    es_iconnecta: false,
    snackbar: false,
    disabled: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    es_medyola:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] === 'Medyola'
        ? true
        : false,
    headers_entidad: [
      { text: 'Fecha Alta', align: 'center', value: 'fecha' },
      { text: 'Descripción', align: 'left', value: 'entidad' },
      { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },
    ],
    headers_persona: [
      { text: 'Nombre', align: 'center', value: 'nombre' },
      { text: 'Telefono', align: 'center', value: 'telefono' },
      { text: 'Email', align: 'center', value: 'email' },
      { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },
    ],
    headers_local: [
      { text: 'Nombre', align: 'center', value: 'nombre' },
      { text: 'Entidad', align: 'center', value: 'entidad' },
      { text: 'Persona Contacto', align: 'center', value: 'persona' },
      { text: 'Dirección', align: 'center', value: 'direccion' },
      { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },
    ],
    headers_dispositivo: [
      { text: 'Descripcion', align: 'center', value: 'descripcion' },
      { text: 'Local', align: 'center', value: 'local_nombre' },
      { text: 'Tipo', align: 'center', value: 'tipo' },
      { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },
    ],
    tipo: [
      { name: 'Venta', id: 1 },
      { name: 'Alquiler', id: 2 },
    ],
    dispositivos: [],
    locales: [],
    personas_contacto: [],
    entidades: [],
    dialog: {
      titulo: '',
      texto: '',
      accion: '',
      tipo: '',
      id: 0,
      dialog: false,
    },
    dialogedit: false,
    editedIndex: -1,
    editedItem: {
      id: 0,
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      descripcion: '',
      direccion: '',
      poblacion: '',
      entidad: 0,
      local: 0,
      persona: 0,
      tipo: 1,
    },
  }),
  created() {
    this.$http.post(dispositivosUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
      if (response.status === 200 && response.data) {
        this.loading = false;
        this.dispositivos = response.data.dispositivos;
        this.locales = response.data.locales;
        this.personas_contacto = response.data.personas_contacto;
        this.entidades = response.data.entidades;
      }
    });
  },

  methods: {
    confirmarDialog() {
      this.$http
        .post(
          dispositivosEditUrl,
          { user_id: this.authUser.id, objeto_id: this.dialog.id, accion: this.dialog.accion, tipo: this.dialog.tipo },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.alerta.texto = this.$t('success.save_common');
            this.alerta.icono = 'mdi-check';
            this.alerta.color = 'success';
            this.dispositivos = response.data.dispositivos;
            this.locales = response.data.locales;
            this.personas_contacto = response.data.personas_contacto;
            this.entidades = response.data.entidades;
            this.dialog.dialog = false;
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.icono = 'mdi-alert-circle';
            this.alerta.color = 'warning';
          }
          this.snackbar = true;
          this.loading = false;
        });
    },
    mostrarModal(accion, tipo, id, indice) {
      this.dialog.dialog = false;
      this.resetEditItem();

      if (accion === 'new') {
        this.dialog.titulo = this.$t('dispositivo.new_' + tipo);
        this.dialogedit = true;
      } else if (accion === 'edit') {
        this.dialog.titulo = this.$t('dispositivo.edit_' + tipo);
        this.dialogedit = true;
        this.formEditItem(tipo, id, indice);
      } else if (accion === 'delete') {
        if (tipo === 'dispositivo' || tipo === 'local' || tipo === 'entidad' || tipo === 'persona') {
          this.dialog.titulo = this.$t('dispositivo.titulo_eliminar', { tipo: this.$tc('dispositivo.' + tipo, 1) });
          this.dialog.texto = this.$t('dispositivo.texto_eliminar', { tipo: this.$tc('dispositivo.' + tipo, 1) });
          this.dialog.dialog = true;
          this.dialog.id = id;
        }
      }
      this.dialog.accion = accion;
      this.dialog.tipo = tipo;
    },

    formEditItem(tipo, id, indice) {
      if (tipo === 'entidad') {
        this.editedItem.descripcion = this.entidades[indice].nombre;
      } else if (tipo === 'local') {
        this.editedItem.nombre = this.locales[indice].nombre;
        this.editedItem.entidad = this.locales[indice].entidad_id;
        this.editedItem.persona = this.locales[indice].persona;
        this.editedItem.direccion = this.locales[indice].direccion;
        this.editedItem.poblacion = this.locales[indice].poblacion;
      } else if (tipo === 'dispositivo') {
        this.editedItem.descripcion = this.dispositivos[indice].descripcion;
        this.editedItem.tipo = this.dispositivos[indice].tipo;
        this.editedItem.local = this.dispositivos[indice].local_id;
      } else if (tipo === 'persona') {
        this.editedItem.nombre = this.personas_contacto[indice].nombre;
        this.editedItem.apellidos = this.personas_contacto[indice].apellidos;
        this.editedItem.telefono = this.personas_contacto[indice].telefono;
        this.editedItem.email = this.personas_contacto[indice].email;
      }
      this.editedItem.id = id;
      this.editedIndex = indice;
    },

    validar_form() {
      let valido = false;
      if (this.dialog.tipo === 'entidad' && this.editedItem.descripcion !== '') {
        valido = true;
      } else if (this.dialog.tipo === 'persona' && this.editedItem.nombre !== '' && this.editedItem.apellidos !== '') {
        valido = true;
      } else if (this.dialog.tipo === 'local' && this.editedItem.entidad !== 0) {
        valido = true;
      } else if (
        this.dialog.tipo === 'dispositivo' &&
        this.editedItem.local !== 0 &&
        this.editedItem.descripcion !== ''
      ) {
        valido = true;
      }
      return valido;
    },
    quitarPersona() {
      this.editedItem.persona = '';
    },
    save() {
      if (this.validar_form()) {
        const ruta = this.editedItem.id > 0 ? dispositivosEditUrl : dispositivosNewUrl;
        this.$http
          .post(
            ruta,
            {
              user_id: this.authUser.id,
              objeto_id: this.editedItem.id,
              objeto: this.editedItem,
              accion: this.dialog.accion,
              tipo: this.dialog.tipo,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.alerta.texto = this.$t('success.save_common');
              this.alerta.icono = 'mdi-check';
              this.alerta.color = 'success';
              this.dispositivos = response.data.dispositivos;
              this.locales = response.data.locales;
              this.personas_contacto = response.data.personas_contacto;
              this.entidades = response.data.entidades;
              this.dialogedit = false;
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.icono = 'mdi-alert-circle';
              this.alerta.color = 'warning';
            }
            this.snackbar = true;
            this.loading = false;
          });
      } else {
        this.alerta.texto = this.$t('errors.form_invalid');
        this.alerta.icono = 'mdi-alert-circle';
        this.alerta.color = 'warning';
        this.snackbar = true;
      }
    },
    resetEditItem() {
      this.dialog.id = 0;
      this.editedIndex = -1;
      this.editedItem.nombre = '';
      this.editedItem.apellidos = '';
      this.editedItem.telefono = '';
      this.editedItem.email = '';
      this.editedItem.descripcion = '';
      this.editedItem.entidad = 0;
      this.editedItem.local = 0;
      this.editedItem.persona = '';
      this.editedItem.direccion = '';
      this.editedItem.poblacion = '';
      this.editedItem.entidad = 1;
      this.editedItem.id = 0;
    },
  },
};
</script>
<style scoped lang="css">
.valoracion_star {
  font-size: 16px;
  margin-right: -4px;
}
</style>
